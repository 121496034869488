import React, { Component } from "react";

import logo from "../../assets/Figma/FavCon 1.png";
import {InlineShareButtons} from 'sharethis-reactjs';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";


const SubShare = (props) => {

 
  return (
    <div className="d-flex">
       <FacebookShareButton
        url={props.only_invite_url}
        quote={props.invite_url_title}
        hashtag={"#Ownerscope"}
        description={"hellopooo"}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={40}  /> 
      </FacebookShareButton>
      <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'cta',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              
              'messenger',
              'whatsapp',
              'linkedin',
              'email',
              ' sms',
              'twitter'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40,             // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            // url: networks[0] === 'facebook' ? props.only_invite_url : props.invite_url_title+props.only_invite_url, // (defaults to current url)
            url:  props.only_invite_url, // (defaults to current url)
            image: logo,
            description: props.invite_url_title,       // (defaults to og:description or twitter:description)
            title: props.invite_url_title,            // (defaults to og:title or twitter:title)
            message: props.invite_url_title + props.only_invite_url,     // (only for email sharing)
            subject: 'Hey! Check out Ownerscope.',  // (only for email sharing)
            username: 'twitter handle' // (only for twitter sharing)
          }}
        />
        <TwitterShareButton
        title={props.invite_url_title}
        url={ props.only_invite_url}
        hashtags={["Ownerscope"]}
      >
        <TwitterIcon size={40}  />
        
      </TwitterShareButton>
    </div>
  )
}

export default SubShare
