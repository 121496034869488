
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";

import users from "../../../assets/Figma/userVector.png";

import moreinsight from "../../../assets/Figma/moreinsight.png";

import setcore from "../../../assets/Figma/setcore.png";
import { useNavigate } from "react-router-dom";
const MenuForMoreInsight = () => {
  const navigate = useNavigate();

  // const SetCore = () => {
  //   navigate("/SetCore");
  // };

  const GoBack = () => {
    navigate("/Question",{ state: `setValue(14)`});
  };

  return (
    <>
      <BottomNavigation showLabels>
        <BottomNavigationAction
        onClick={GoBack}
          label="Invite Circle"
          icon={
            <img
              className="footer-icon filter-blue"
              src={users}
              alt="MoreInsight"
            />
          }
        />
        <BottomNavigationAction
          
          label="Set Core"
          icon={
            <img
              className="footer-icon filter-blue"
              src={setcore}
              alt="SetCore"
            />
          }
        />
        <BottomNavigationAction
          label="More Insight"
          icon={
            <img
              className="footer-icon filter-blue"
              src={moreinsight}
              alt="MoreInsight"
            />
          }
        />
      </BottomNavigation>
    </>
  );
};

export default MenuForMoreInsight;