import React from "react";
import Banner from "../Banner/Banner";
import logo from "../../assets/Figma/FavCon 1.png";
import Footer from "./Footer";
const Home = () => {
  return (
    <>
    <div className="container-fluid" style={{maxWidth:'98%'}}>
      <Banner />
    </div>
    <Footer logo={logo}/>
    </>
  );
};

export default Home;
