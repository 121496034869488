import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';

import Vector from '../../assets/Figma/Vector.png'
import { padding } from '@mui/system';

export default function RightDrawer() {
  const [state, setState] = React.useState({
    right: false,
  });
  const closeButton ={
  
        "font-family":" 'Poppins'",
        "font-style":" normal",
        "font-weight":" 700",
        "font-size":" 21px",
        "line-height":" 21px",
        "display": "flex",
        "align-items":" center",
        "text-align":" center",
        "text-transform":" uppercase",
        "color": "#FFFFFF",
        "border":" 1px solid #ffff",
        "margin":" 6px auto",
        "width":" 100%",
 
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (<>
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
   <div className='drawer p-3'><p><b>Ownerscope</b> is an ice-breaker to help friends and families talk comfortably about the goal of owning property.</p><p>  People who actively talk with their circle about owning property are more likely to achieve their goal. 

Ownerscope makes it easy to have these valuable conversations. </p><p> Just complete the personality quiz, then ask the people in your circle, “What’s your Ownerscope?”</p></div> 

    </Box>
    <div style={{backgroundColor:'#224388',padding:'5px'}}>
        <Button onClick={toggleDrawer(anchor, false)} variant="outlined" className='closeButton' style={closeButton}>Close </Button>
    </div>
    </>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><img src={Vector} alt='Vector' /></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
          
        </React.Fragment>
      ))}
    </div>
  );
}
