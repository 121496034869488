import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
 
  return (
    <div className="container-fluid" style={{ maxWidth: "100%" ,padding:'0px',backgroundColor:`${props.backgroundColor}`,position:`${props.position}`,left:`${props.left}`,right:`${props.right}`,bottom:`${props.bottom}`}} >
      <footer style={{ marginLeft:`${props.marginLeft}`}} className='d-flex'>
        {props.logo?<img src={props.logo} alt="logo" className="footer_logo hideDesktop"/>:null} 
        <div className="py-3 px-4 text-light">
          © Ownerscope Worldwide LLC | All rights reserved | &nbsp;<Link to="/">  Privacy Policy   </Link>  &nbsp; &  &nbsp; <Link to="/"> Terms of Use</Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
