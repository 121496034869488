import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import YourResultDesktopBg from "../../assets/Figma/deskop-bckground.png";
import logo from "../../assets/Figma/FavCon 1.png";
import Bookmark from "../../assets/Figma/bookmark.png";
import AppMenu from "../AppBar/AppBar";
import MenuForMoreInsight from "./Menu/MenuForMoreInsight";
import Footer from "./Footer";
import api from "../../Api";

import ScrollToTop from '../scrollToTop'

const MoreInsight = () => {
  const location = useLocation();

  const [longtail, setlongtail] = useState([]);

  useEffect(() => {
    api
      .get("analytics_longtail")
      .then((response) => {
        setlongtail(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const [Insight, setInsight] = React.useState({
    Financing: "Select Options",
    Culture_and_Lifestyle: "Select Options",
    Family_and_Relationships: "Select Options",
    Location: "Select Options",
    Design_and_Tech: "Select Options",
  });
  // console.log("SELECTED Insight", Insight);

  const handleChange = (event) => {
    setInsight(event.target.value);
    setInsight({
      ...Insight,
      [event.target.name]: event.target.value,
    });
    resultUrlCopy(event);
  };
  const resultUrlCopy = (event) => {
    navigator.clipboard.writeText(event.target.value);
  };

  // bookmark url

  // function addBookmark(url, title){
  //   if (!url) {url = window.location}
  //     if (!title) {title = document.title}
  //     var browser=navigator.userAgent.toLowerCase();
  //     if (window.sidebar) { // Mozilla, Firefox, Netscape
  //       window.sidebar.addPanel(title, url,"");
  //     } else if( window.external) { // IE or chrome
  //       if (browser.indexOf('chrome')==-1){ // ie
  //         window.external.AddFavorite( url, title);
  //       } else { // chrome
  //         // console.log('Please Press CTRL+D (or Command+D for macs) to bookmark this page');
  //       }
  //     }
  //     else if(window.opera && window.print) { // Opera - automatically adds to sidebar if rel=sidebar in the tag
  //       return true;
  //     }
  //     else if (browser.indexOf('konqueror')!=-1) { // Konqueror
  //       // console.log('Please press CTRL+B to bookmark this page.');
  //     }
  //     else if (browser.indexOf('webkit')!=-1){ // safari
  //       // console.log('Please press CTRL+B (or Command+D for macs) to bookmark this page.');
  //     } else {
  //       // console.log('Your browser cannot add bookmarks using this link. Please add this link manually.')
  //     }
  //   }

  return (
    <>
      <div
        style={{ backgroundImage: `url(${YourResultDesktopBg}),linear-gradient(90deg,      #ffffff 45.53%,      rgba(255, 255, 255, 0) 96.41%)` }}
        className="MoreInsight result question_bg"
      >
        <div className="result_appbar">
          <AppMenu />
        </div>
        <div className="Desktop_menu">
          <MenuForMoreInsight />
        </div>
        <div
          className="d-flex Bookmark"
          style={{
            backgroundColor: "#D3F8BC",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "15px 15px 0px 15px",
          }}
         
        >
          <p>Please bookmark this page!</p>
          <p>
            <img src={Bookmark} alt={"Bookmark"} loading="lazy" />
          </p>
        </div>
        <div className="question d-flex result-flex flex-row flex-wrap" id="setcore">
          <div className="question_data ml-5 ">
            <div>
              <div
                className="result checkboxGroup"
                style={{ margin: "0px 20px" }}
              >
                <h1>Insight</h1>
                <p style={{ padding: "20px 0px" }}>
                  Knowledge is knowing what questions to ask. Copy any of the
                  strings below to search for relevant content on Google,
                  YouTube, or your favorite sources.
                </p>

                {location.state.map((element) => {
                  if (element == "Culture and Lifestyle") {
                    return (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <b>Culture and Lifestyle</b>
                        <FormHelperText sx={{ margin: "10px 0px" }}>
                          <b>Example:</b>
                          <br />
                          <span>Can I crowdfund my downpayment?</span>
                        </FormHelperText>

                        <Select
                          value={Insight.Culture_and_Lifestyle}
                          onChange={handleChange}
                          id="myInput"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="Culture_and_Lifestyle"
                        >
                          <MenuItem value="Select Options">
                            Select Options
                          </MenuItem>
                          {longtail.map((data, i, row) => {
                            return data?.category ===
                              "Culture and Lifestyle" ? (
                              <MenuItem value={data?.longtail_phrase}>
                                {data?.longtail_phrase}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                        {Insight.Culture_and_Lifestyle != "Select Options" ? (
                          
                            <p style={{fontSize:'16px'}}>
                             Copied to clipboard ! Now open
                              <b>
                                <a
                                  href="https://www.google.com/"
                                  target="_blank"
                                  style={{ color: "r4665aa !important" }}
                                >
                                  Google
                                </a>
                              </b>
                              and paste this selection to start your search.
                            </p>
                        
                        ) : null}
                      </FormControl>
                    );
                  }

                  if (element == "Financing") {
                    return (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <b>Financing</b>
                        <FormHelperText sx={{ margin: "10px 0px" }}>
                          <b>Example</b>:<br />
                          <span>Can I crowdfund my downpayment?</span>
                        </FormHelperText>
                        <Select
                          value={Insight.Financing}
                          onChange={handleChange}
                          id="myInput"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="Financing"
                        >
                          <MenuItem value="Select Options">
                            Select Options
                          </MenuItem>
                          <MenuItem value="">
                            <em>Select Options </em>
                          </MenuItem>
                          {longtail.map((data, i, row) => {
                            return data?.category === "Financing" ? (
                              <MenuItem value={data?.longtail_phrase}>
                                {data?.longtail_phrase}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                        {Insight.Financing != "Select Options" ? (
                          
                            <p style={{fontSize:'16px'}}>
                              Copied to clipboard ! Now open
                              <b>
                                <a
                                  href="https://www.google.com/"
                                  target="_blank"
                                  style={{ color: "r4665aa !important" }}
                                >
                                  Google
                                </a>
                              </b>
                              and paste this selection to start your search.
                            </p>
                          
                        ) : null}
                      </FormControl>
                    );
                  }

                  if (element == "Location") {
                    return (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <b>Location</b>
                        <FormHelperText sx={{ margin: "10px 0px" }}>
                          <b>Example:</b>
                          <br />
                          <span>Which cities have lowest income tax?</span>
                        </FormHelperText>
                        <Select
                          value={Insight.Location}
                          onChange={handleChange}
                          id="myInput"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="Location"
                        >
                          <MenuItem value="Select Options">
                            Select Options
                          </MenuItem>
                          {longtail.map((data, i, row) => {
                            return data?.category === "Location" ? (
                              <MenuItem value={data?.longtail_phrase}>
                                {data?.longtail_phrase}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                        {Insight.Location != "Select Options" ? (
                          
                            <p style={{fontSize:'16px'}}>
                              Copied to clipboard ! Now open
                              <b>
                                <a
                                  href="https://www.google.com/"
                                  target="_blank"
                                  style={{ color: "r4665aa !important" }}
                                >
                                  Google
                                </a>
                              </b>
                              and paste this selection to start your search.
                            </p>
                          
                        ) : null}
                      </FormControl>
                    );
                  }

                  if (element == "Design and Tech") {
                    return (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <b>Design and Technology</b>
                        <FormHelperText sx={{ margin: "10px 0px" }}>
                          <b>Example:</b>
                          <br />
                          <span>Can I crowdfund my downpayment?</span>
                        </FormHelperText>
                        <Select
                          value={Insight.Design_and_Tech}
                          onChange={handleChange}
                          id="myInput"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="Design_and_Tech"
                        >
                          <MenuItem value="Select Options">
                            Select Options
                          </MenuItem>
                          {longtail.map((data, i, row) => {
                            return data?.category === "Design and Tech" ? (
                              <MenuItem value={data?.longtail_phrase}>
                                {data?.longtail_phrase}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                        {Insight.Design_and_Tech != "Select Options" ? (
                          
                            <p style={{fontSize:'16px'}}>
                              Copied to clipboard ! Now open
                              <b>
                                <a
                                  href="https://www.google.com/"
                                  target="_blank"
                                  style={{ color: "r4665aa !important" }}
                                >
                                  Google
                                </a>
                              </b>
                              and paste this selection to start your search.
                            </p>
                          
                        ) : null}
                      </FormControl>
                    );
                  }

                  if (element == "Family and Relationships") {
                    return (
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <b>Relationships and Family</b>
                        <FormHelperText sx={{ margin: "10px 0px" }}>
                          <b>Example:</b>
                          <br />
                          <span>What makes two people compatible?</span>
                        </FormHelperText>

                        <Select
                          value={Insight.Family_and_Relationships}
                          onChange={handleChange}
                          id="myInput"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="Family_and_Relationships"
                        >
                          <MenuItem value="Select Options">
                            Select Options
                          </MenuItem>
                          {longtail.map((data, i, row) => {
                            return data?.category ===
                              "Family and Relationships" ? (
                              <MenuItem value={data?.longtail_phrase}>
                                {data?.longtail_phrase}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                        {Insight.Family_and_Relationships !=
                        "Select Options" ? (
                         
                            <p style={{fontSize:'16px'}}>
                              Copied to clipboard ! Now open
                              <b>
                                <a
                                  href="https://www.google.com/"
                                  target="_blank"
                                  style={{ color: "r4665aa !important" }}
                                >
                                  Google
                                </a>
                              </b>
                              and paste this selection to start your search.
                            </p>
                          
                        ) : null}
                      </FormControl>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center renting" id="Invite_Circle">
          <h1 className="my-5">Owning, renting, investing. </h1>
          <p>
            These are influenced by your social circles and your personality
            type – we call this your Ownerscope.
          </p>
          <h1 className="my-4">Everyone has an Ownerscope. </h1>
          <p>Now you know yours.</p>
          <Link to="/">
          <img
            src={logo}
            alt={logo}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://admin.ownerscope.com/QuestionImage/WeClosed.jpg";
            }}
            className="text-center d-flex"
            style={{ margin: "40px auto" }}
          /></Link>
        </div>
        <ScrollToTop />
      </div>
      <Footer
        backgroundColor={"#173065"}
        position={"fixed"}
        left={"0"}
        right={"0"}
        bottom={"0"}
      />
    </>
  );
};

export default MoreInsight;
